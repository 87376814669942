<template lang="pug">
    .w-full
        .heading-submenu.w-full.flex.items-center.justify-between
            h2.subheading Daftar Riwayat Panggilan
            .flex.items-center.gap-2

                .block.w-54(v-if="detail.msisdn && detail.msisdn.length > 0")
                    .field.field--icon-right.w-full
                        select.field-style(v-model="selected_msisdn" @change="changeMsisdn()")
                            option(v-for="(item,index) in detail.msisdn" :key="index") {{ item }}
                        span.icon-field
                            b-icon(name="chevron-down")
                            
                .box-calendar.my-4.w-64
                    b-icon(name="calendar")
                    date-range-picker(
                        v-if="dateRange.startDate && dateRange.endDate"
                        ref="picker"
                        v-model="dateRange"
                        @update="pickerUpdate($event)"
                        :locale-data="{ firstDay: 1, format: 'dd mmmm yyyy' }"
                        :date-format="dateFormatPicker"
                        opens="left"
                        :ranges="default_ranges"
                        :autoApply="true"
                    )
                        template(slot="input" slot-scope="picker") {{ picker.startDate | date }} - {{ picker.endDate | date }}

        .card-bg-theme.p-6.rounded.shadow.w-full
            table.main-table
                thead
                    tr.uppercase
                        th(v-for="(th,index) in tableThead" :key="index" :class="(th.number && th.number===true ? 'number' : '')+(th.action && th.action===true ? ' thead-action' : '')") {{ th.name }}
                tbody
                    tr.text-sm(v-if="status_detail === 'loading' || status_call_history.status === 'loading'")
                        td.text-center(:colspan="tableThead.length")
                            .relative.p-10
                                b-loading(text="Memuat data")
                    
                    template(v-else-if="call_history && call_history.result && call_history.result.data && Object.keys(call_history.result.data).length > 0")
                        tr.text-sm(v-for="(data,index) in call_history.result.data" :key="index")
                            td {{ data.record_time ? data.record_time.substr(0, 10) : '-'}}
                            td {{ data.nik ? data.nik : '-'}}
                            td {{ data.name ? data.name : '-'}}
                            td {{ data.call_type ? data.call_type : '-'}}
                            td {{ data.a_number ? data.a_number : '-'}}
                            td {{ data.b_number ? data.b_number : '-'}}
                            td {{ data.duration ? data.duration : '-'}}
                            td {{ data.count ? data.count : '-'}}
                            td {{ data.lac_ci ? data.lac_ci : '-'}}
                    
                    tr.text-sm(v-else)
                        td.text-center.p-10(:colspan="tableThead.length") Data tidak ditemukan.
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
    name: 'MsisdnCallHistory',
    components: {
        DateRangePicker
    },
    data() {
        return {
            tableThead: [
                {
                    name: 'Date',
                    // number: true,
                },
                {
                    name: 'NIK',
                },{
                    name: 'Name',
                },{
                    name: 'Call Type',
                },{
                    name: 'From',
                },{
                    name: 'To',
                },{
                    name: 'Duration',
                },{
                    name: 'Count',
                },{
                    name: 'LAC',
                },
            ],

            selected_msisdn: '',

            dateRange: {
                // startDate: moment().subtract(2, 'months').toDate(),
                // startDate: moment().toDate(),
                startDate: this.getDefaultFromDate(),
                endDate: moment().toDate(),
            },
            default_ranges: {
                'Hari ini': [moment().toDate(), moment().toDate()],
                'Kemarin': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                '7 hari lalu': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Minggu lalu': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
            },
        }
    },
    computed: {
        ...mapState('actor', [
            'status_detail',
            'detail',
            'status_call_history',
            'call_history',
        ]),
    },
    watch: {
        status_detail(newVal) {
            if (newVal.status === 'loading')
                this.getData(true);
        },
        detail(newVal) {
            //- console.log(newVal);
            
            //- temporary -> dummy data
            //- newVal.msisdn = '6288215243013';
            
            if (newVal && newVal.msisdn && newVal.msisdn.length > 0) {
                this.selected_msisdn = newVal.msisdn[0];
                this.getData(false);
            }
            //-  else {

            //-     this.getData(true);
            //- }
        },
    },
    methods: {
        async pickerUpdate(e) {
            // let ranges = moment(e.endDate.getTime()).diff(moment(e.startDate.getTime()), 'days') + 1;

            // this.date_from = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            // this.date_to = moment(this.dateRange.endDate).format('YYYY-MM-DD');
            this.getData(false);
        },
        changeMsisdn() {
            this.getData(false);
        },
        async getData(loading=false) {
            if (loading === true) {
                let status = this.status_call_history;

                if (status.status === 'loading' && status.cancel === null) {
                    status.status = false;

                } else {
                    status.status = 'loading';
                }

                this.$store.commit('actor/setStatusCallHistory', status);
            
            } else {
                //- this.$store.dispatch('actor/getCallHistory', [this.detail.msisdn[index_msisdn], moment(this.dateRange.startDate).format('YYYY-MM-DD'), moment(this.dateRange.endDate).format('YYYY-MM-DD')]);
                this.$store.dispatch('actor/getCallHistory', [this.selected_msisdn, moment(this.dateRange.startDate).format('YYYY-MM-DD'), moment(this.dateRange.endDate).format('YYYY-MM-DD')]);
            }
        },
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (to.name === 'MsisdnOfActorCallHistory') {
                vm.$store.commit('actor/setCallHistory', {});
                if (vm.detail && vm.detail.msisdn && vm.detail.msisdn.length > 0) {
                    vm.selected_msisdn = vm.detail.msisdn[0];
                    vm.getData(false);
                }
                //-  else {
                //-     vm.getData(true);
                //- }
            }
        });
    },
}
</script>

<style lang="sass">

</style>